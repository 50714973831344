
















































































































































































































































































































































































































































































































































































































































































































































































































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import Vue from 'vue';
import { CHANGE_MEMBER_STATUS_SA, UPDATE_TRIAL_ACTIVITY } from 'src/utils/apollo-mutations';
import {
  ALL_USERS,
  APPS_GRAPH,
  APPS_GRAPH_FOR_COMPANY,
  APPS_REPORTS_FOR_COMPANY,
  CAMPAIGN_REPORTS,
  CAMPAIGN_REPORTS_FOR_COMPANY,
  COMPANY_DETAILS,
  END_SUBSCRIPTION,
  GET_INTEGRATION,
  GET_INTEGRATION_FOR_COMPANY,
  GET_TRIAL_ACTIVITY,
  SUB_ACTIVATE_DATE,
  TARGETING_KEYWORD_REPORTS_FOR_COMPANY,
  TRIGGER_MANUAL_SYNC,
  GET_LOGIN_ACTIVITY,
  GENERATE_REST_PASSWORD_LINK,
  TOGGLE_SIGNUP,
  GET_GLOBAL_AI_CREDITS,
} from 'src/utils/apollo-queries';
import { GET_CHECKLIST } from 'src/utils/apptweakqueries';
import VueMask from 'v-mask';
import { SignUpService } from '@services/signup.service';
import { Roles } from 'src/utils/enums';
import { DEFAULT_GRANULARITY } from '@shared/config';
import ChartForCompany from 'src/components/ads-manager/ChartForCompany.vue';
import * as _ from 'lodash';
import { ALL_INVOICES, CB_Subs, CHANGE_TERM_END, RE_INVITE_CA, MANUAL_SPEND_CHECK, TOGGLE_AUTOMATION_V2 } from 'src/utils/apptweakqueries';
import * as moment from 'moment';
import { decodeToken } from '@shared/utils/commom.util';
import { LSS } from 'src/core/services';
import * as momentTz from 'moment-timezone';
import DeleteCompany from 'src/components/super-admin/DeleteCompany.vue';
import DeleteTestUsers from 'src/components/super-admin/DeleteTestUsers.vue';
import ArchiveStaffMember from 'src/components/super-admin/ArchiveStaffMember.vue';
import DecisionAlert from 'src/components/DecisionAlert.vue';
import SwitchUserAccount from 'src/components/super-admin/SwitchUserAccount.vue';
import { SignUpService } from '@services/signup.service';
import DeleteIntegration from 'src/components/integration/DeleteIntegration.vue';
import AICredits from 'src/components/aICredits.vue';
import RemoveUser from 'src/components/super-admin/RemoveUser.vue';
import { BLOCKED_DOMAINS } from 'src/utils/constants';
import { UPDATE_SUBSCRIPTION } from 'src/utils/apollo-mutations';
import { UserService } from 'src/services/feature-services/user.service';

Vue.use(VueMask);
export default Vue.extend({
  name: 'Users',
  components: {
    ChartForCompany,
    DeleteCompany,
    DeleteTestUsers,
    ArchiveStaffMember,
    DecisionAlert,
    SwitchUserAccount,
    DeleteIntegration,
    AICredits,
    RemoveUser,
  },
  data() {
    const today = moment().utc().format('YYYY-MM-DD');
    const dayAWeekAgo = moment().utc().subtract(7, 'days').format('YYYY-MM-DD');
    return {
      onAutomationV2CurrVal: false,
      onAutomationV2TeamID: '',
      globalAiCreditsLimit: null,
      userCreditsData: {},
      enableSS: false,
      on: '',
      attrs: '',
      moment: moment,
      syncStatus: false,
      locale: 'en',
      syncing: false,
      appsReportFinal: null,
      createUserValid: false,
      createUserDialog: false,
      createUserEmail: '',
      fullscreenLoader: null,
      decisionAlertFlag: false,
      decisionAlertTitle: 'Are you sure you want to end subscription?',
      decisionAlertIcon: 2,
      decisionAlertMessage: '',
      decisionAlertData: {},
      switchUserMetadata: {},

      message: '',
      msgTitle: 'info',
      memStatus: false,
      email: '',
      password: '',
      checkbox: false,
      inviteDialog: false,
      select: '',
      emailForInvite: '',

      text: 'text',

      addMemberDialog: false,
      msgModel: false,
      loading: false,
      blDomains: BLOCKED_DOMAINS,
      Role: null,
      deleteTrialCheck: false,
      invoiceCheck: false,
      memberDetails: [],
      companiesList: [],
      activeCompanies: [],
      deletedCompanies: [],
      disabledCompanies: [],
      selectedCompanies: { text: 'All Companies', value: 'all' },
      companies: [
        { text: 'All Companies', value: 'all' },
        { text: 'Active Companies', value: 'active' },
        { text: 'Disabled Companies', value: 'disabled' },
        { text: 'Archived Companies', value: 'deleted' },
      ],

      isCheckboxTrue: false,

      onClickShow: false,
      memberValid: false,

      tabs: null,
      detailDialog: false,
      companyDeletionMetadata: {},
      testUsersMetadata: {},
      archiveStaffMemberData: {},

      //overview
      totalSpend: '0',
      totalTapInstalls: 0,
      keywordsCount: 0,
      liveReportsCount: 0,
      totalIntegration: 0,
      trailEndDate: null,
      subActivatedDate: null,
      trailEndDateInFuture: false,
      sub_id: null,
      subStatus: null,
      datePicker: false,
      newTrialEndDate: null,
      minTrialEndDate: moment().utc().subtract(-1, 'days').format('YYYY-MM-DD'),

      //apps chart
      granularity: DEFAULT_GRANULARITY,
      selectedApp: 0,
      appsChartData: {
        labels: [],
        datasets: [],
        appList: [],
      },

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
      },
      rangePicker: false,
      filterDateRangeModel: [dayAWeekAgo, today],
      filterDateRange: [dayAWeekAgo, today],
      reportDateRange: [moment().utc().subtract(30, 'days').format('YYYY-MM-DD'), today],

      //Integration
      integrations: null,

      //Staff
      search: '',
      selectedTeamId: '',
      selectedUserId: '',
      companyAdmin: [
        {
          FirstName: null,
          LastName: null,
          Phone: null,
          Email: null,
          CompanyName: null,
          JobTitle: null,
          InviteStatus: null,
          IsDisabled: null,
          CreatedOn: '',
          Role: null,
        },
      ],
      companyStaff: [],

      //apps
      appsReport: null,
      appsReportCount: 0,

      //campaigns
      liveCampaignReports: null,
      campaignReport: null,

      appId: [],

      totalCampInApp: [],
      //Payment and Invoice
      cardsHeaders: [
        {
          text: 'Card_Id',
          align: 'start',
          sortable: false,
          value: 'card.id',
        },
        { text: 'Type', value: 'card.type' },
        { text: 'Number', value: 'plus' },
        { text: 'Expiry', value: 'card.expiry' },
        { text: 'Action', value: 'actions' },
      ],
      loginActivityHeaders: [
        {
          text: 'Date',
          align: 'start',
          sortable: false,
          value: '_id',
        },
        { text: 'Users', value: 'LoginActivity' },
        { text: 'User Count', value: 'LoginActivityCount' },
        { text: 'Action', value: 'actions' },
      ],
      loginActivity: [],
      usageDialog: false,
      loginActivityDetailHeaders: [
        {
          text: 'Session Time',
          align: 'start',
          sortable: false,
          value: 'TimeStamp',
        },
        { text: 'User who has login', value: 'user' },
        { text: 'Browser type', value: 'Browser' },
      ],
      loginActivityDetail: [],
      check0: 'mdi-checkbox-blank-circle-outline',
      check1: 'mdi-checkbox-blank-circle-outline',
      check2: 'mdi-checkbox-blank-circle-outline',
      check3: 'mdi-checkbox-blank-circle-outline',
      check4: 'mdi-checkbox-blank-circle-outline',
      check5: 'mdi-checkbox-blank-circle-outline',
      check6: 'mdi-checkbox-blank-circle-outline',
      mmpIntCheck: 'mdi-checkbox-blank-circle-outline',
      createMmpGoalsCheck: 'mdi-checkbox-blank-circle-outline',
      scheduledReportsCheck: 'mdi-checkbox-blank-circle-outline',
      campGenCheck: 'mdi-checkbox-blank-circle-outline',
      check7: 'mdi-checkbox-blank-circle-outline',
      check8: 'mdi-checkbox-blank-circle-outline',
      check9: 'mdi-checkbox-blank-circle-outline',
      check10: 'mdi-checkbox-blank-circle-outline',
      check11: 'mdi-checkbox-blank-circle-outline',
      check12: 'mdi-checkbox-blank-circle-outline',
      check13: 'mdi-checkbox-blank-circle-outline',
      check14: 'mdi-checkbox-blank-circle-outline',
      check15: 'mdi-checkbox-blank-circle-outline',
      check16: 'mdi-checkbox-blank-circle-outline',
      color0: '',
      color1: '',
      color7: '',
      color2: '',
      color6: '',
      mmpIntColor: '',
      createMmpGoalsColor: '',
      scheduledReportsColor: '',
      campGenColor: '',
      color3: '',
      color4: '',
      color5: '',
      color8: '',
      color9: '',
      color10: '',
      color11: '',
      color12: '',
      color13: '',
      color14: '',
      color15: '',
      color16: '',
      showCL: false,
      existCL: true,
      resetLinkDialog: false,
      resetLinkMsg: 'The user can use the above link to set a new password',
      resetLink: '',

      integrationData: null,

      removeUserValid: false,
      removeUserDialog: false,
      removeUserEmail: null,
      removeUserMetadata: {},
    };
  },

  computed: {
    selectedCompanyImage() {
      const pic = this.companyAdmin[0]?.picture;
      const defaultImage = require('../../assets/images/Kochva-icon.png');
      if (pic && typeof pic === 'string') return pic;
      return defaultImage;
    },
    memberheaders: {
      cache: false,
      get() {
        return [
          { text: this.$t('teamMgmt.company'), value: 'CompanyName' },
          { text: this.$t('teamMgmt.inviteStatus'), value: 'InviteStatus' },
          { text: 'Enable/Disable <br> Subscription', value: 'IsDisabled' },
          { text: 'GCF', value: 'onAutomationV2' },
          { text: this.$t('teamMgmt.sync'), value: 'Sync', sortable: false },
          { text: 'Added On', value: 'CreatedOn' },
          { text: 'Switch Account', value: 'SwitchAccount', sortable: false },
          { text: 'AI Credits (remaining/total)', value: 'credits', sortable: false },
        ];
      },
    },

    emailRules: {
      get() {
        return [
          (v) => !!v || this.$t('createCampaignRules.emailReq'),
          (v) =>
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || this.$t('createCampaignRules.emailValid'),
          (v) => !/\s/.test(v) || 'Email cannot contain spaces',
          (v) =>
            (v && v.includes('@') && !this.blDomains.includes(v.split('@')[1].toLowerCase())) || 'Please use an enterprise email account',
        ];
      },
    },

    removeEmailRules: {
      get() {
        return [
          (v) => !!v || this.$t('createCampaignRules.emailReq'),
          (v) =>
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) || this.$t('createCampaignRules.emailValid'),
          (v) => !/\s/.test(v) || 'Email cannot contain spaces',
        ];
      },
    },

    staffheaders: {
      cache: false,
      get() {
        return [
          { text: this.$t('teamMgmt.first'), value: 'FirstName' },
          { text: this.$t('teamMgmt.last'), value: 'LastName' },
          { text: this.$t('teamMgmt.email'), value: 'Email' },
          { text: this.$t('teamMgmt.job'), value: 'JobTitle' },
          { text: this.$t('teamMgmt.role'), value: 'Role' },
          { text: this.$t('teamMgmt.inviteStatus'), value: 'InviteStatus' },
          { text: 'Added On', value: 'CreatedOn' },
          { text: 'Actions', value: 'actions' },
        ];
      },
    },

    appsHeaders: {
      get() {
        return [
          {
            text: this.$t('appsHeaders.appName'),
            align: 'start',
            value: 'metadata.app.appName',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.totalCamp'),
            value: 'total.totalCampInApp',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.spend'),
            value: 'total.localSpend.amount',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.tapInstallCPI'),
            value: 'total.tapInstallCPI.amount',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.totalAvgCPI'),
            value: 'total.totalAvgCPI.amount',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.averageCpt'),
            value: 'total.avgCPT.amount',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.impressions'),
            value: 'total.impressions',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.taps'),
            value: 'total.taps',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.tapInstalls'),
            value: 'total.tapInstalls',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.viewInstalls'),
            value: 'total.viewInstalls',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.totalInstalls'),
            value: 'total.totalInstalls',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.ttr'),
            value: 'total.ttr',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.tapInstallRate'),
            value: 'total.tapInstallRate',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.totalInstallRate'),
            value: 'total.totalInstallRate',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.tapNewDownloads'),
            value: 'total.tapNewDownloads',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.viewNewDownloads'),
            value: 'total.viewNewDownloads',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.totalNewDownloads'),
            value: 'total.totalNewDownloads',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.tapRedownloads'),
            value: 'total.tapRedownloads',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.viewRedownloads'),
            value: 'total.viewRedownloads',
            sortable: false,
          },
          {
            text: this.$t('appsHeaders.totalRedownloads'),
            value: 'total.totalRedownloads',
            sortable: false,
          },
        ];
      },
    },

    // 3-Campaign Data Table
    campaignHeaders: {
      get() {
        return [
          {
            text: this.$t('campaignHeaders.appName'),
            align: 'start',
            value: 'metadata.app.appName',
          },
          {
            text: this.$t('campaignHeaders.campaign'),
            value: 'metadata.campaignName',
          },
          {
            text: this.$t('campaignHeaders.campaignStatus'),
            value: 'metadata.campaignStatus',
          },
          {
            text: this.$t('campaignHeaders.spend'),
            value: 'total.localSpend.amount',
          },
          {
            text: this.$t('campaignHeaders.impressions'),
            value: 'total.impressions',
          },
          {
            text: this.$t('campaignHeaders.taps'),
            value: 'total.taps',
          },
          {
            text: this.$t('campaignHeaders.displayStatus'),
            value: 'metadata.displayStatus',
          },
          {
            text: this.$t('campaignHeaders.tapInstalls'),
            value: 'total.tapInstalls',
          },
          {
            text: this.$t('campaignHeaders.viewInstalls'),
            value: 'total.viewInstalls',
          },
          {
            text: this.$t('campaignHeaders.totalInstalls'),
            value: 'total.totalInstalls',
          },
          {
            text: this.$t('campaignHeaders.tapNewDownloads'),
            value: 'total.tapNewDownloads',
          },
          {
            text: this.$t('campaignHeaders.viewNewDownloads'),
            value: 'total.viewNewDownloads',
          },
          {
            text: this.$t('campaignHeaders.totalNewDownloads'),
            value: 'total.totalNewDownloads',
          },
          {
            text: this.$t('campaignHeaders.tapRedownloads'),
            value: 'total.tapRedownloads',
          },
          {
            text: this.$t('campaignHeaders.viewRedownloads'),
            value: 'total.viewRedownloads',
          },
          {
            text: this.$t('campaignHeaders.totalRedownloads'),
            value: 'total.totalRedownloads',
          },
        ];
      },
    },

    filterDateRangeText() {
      if (this.filterDateRange[0] > this.filterDateRange[1]) {
        const maxDate = this.filterDateRange[0];
        this.filterDateRange[0] = this.filterDateRange[1];
        this.filterDateRange[1] = maxDate;
      }
      return this.filterDateRange.join(' - ');
    },
  },

  apollo: {
    companyDetails: {
      query: COMPANY_DETAILS,
      skip() {
        return !this.selectedTeamId;
      },
      variables() {
        return {
          TeamID: this.selectedTeamId,
        };
      },
      update(data) {
        this.setCompanyMemberDetails(data.companyDetails);
      },
    },

    appsReportsForCompany: {
      query: APPS_REPORTS_FOR_COMPANY,
      skip() {
        return !this.selectedTeamId;
      },
      variables() {
        this.fullscreenLoader = this.$loading.show();
        return {
          TeamID: this.selectedTeamId,
          reportDateRange: this.reportDateRange,
        };
      },
      update(data) {
        this.appsReport = data.appsReportsForCompany;
        this.appsGraphFetch(data.appsReportsForCompany.row[0]?.metadata.app.adamId, this.granularity);
        this.selectedApp = data.appsReportsForCompany.row[0]?.metadata.app.adamId;
        this.setAppsReportData(data);
        this.campaignReportsFetch();
      },
    },

    targetingKeywordReportsForCompany: {
      query: TARGETING_KEYWORD_REPORTS_FOR_COMPANY,
      skip() {
        return !this.selectedTeamId;
      },
      variables() {
        return {
          TeamID: this.selectedTeamId,
          reportDateRange: this.reportDateRange,
        };
      },
      update(data) {
        this.keywordsCount = data.targetingKeywordReportsForCompany.row.length;
      },
    },

    getIntegrationForCompany: {
      query: GET_INTEGRATION_FOR_COMPANY,
      skip() {
        return !this.selectedTeamId;
      },
      variables() {
        return {
          TeamID: this.selectedTeamId,
        };
      },
      update(data) {
        this.totalIntegration = data.getIntegrationForCompany.length;
        this.integrations = data.getIntegrationForCompany;
      },
    },

    getTrailActivity: {
      query: GET_TRIAL_ACTIVITY,
      skip() {
        return !this.selectedTeamId;
      },
      variables() {
        return {
          TeamID: this.selectedTeamId,
        };
      },
    },
    getLoginActivities: {
      query: GET_LOGIN_ACTIVITY,
      skip() {
        return !this.selectedTeamId;
      },
      variables() {
        return {
          teamId: this.selectedTeamId,
        };
      },
      update(data) {
        this.loginActivity = data.getLoginActivity.content;
      },
    },
    getCheckList: {
      query: GET_CHECKLIST,
      skip() {
        return !this.selectedTeamId;
      },
      variables() {
        return {
          userId: this.selectedUserId,
        };
      },
      update(data) {
        console.log(data);
        if (data?.getUserCL) {
          const checkList = data?.getUserCL;

          if (checkList.saIntegration && checkList.slackIntegration && checkList.mmpIntegration) {
            this.check0 = 'mdi-check-circle';
            this.color0 = 'primary';
          }
          if (checkList.saIntegration) {
            this.check1 = 'mdi-check-circle';
            this.color1 = 'primary';
          }
          if (checkList.slackIntegration) {
            this.check6 = 'mdi-check-circle';
            this.color6 = 'primary';
          }
          if (checkList.mmpIntegration) {
            this.mmpIntCheck = 'mdi-check-circle';
            this.mmpIntColor = 'primary';
          }
          if (checkList.createMmpGoals) {
            this.createMmpGoalsCheck = 'mdi-check-circle';
            this.createMmpGoalsColor = 'primary';
          }
          if (checkList.scheduledReports) {
            this.scheduledReportsCheck = 'mdi-check-circle';
            this.scheduledReportsColor = 'primary';
          }
          if (checkList.campGen) {
            this.campGenCheck = 'mdi-check-circle';
            this.campGenColor = 'primary';
          }
          if (checkList.kaiComp && checkList.kaiApp && checkList.kaiKeyword && checkList.kaiTop) {
            this.check11 = 'mdi-check-circle';
            this.color11 = 'primary';
          }
          if (checkList.kaiComp) {
            this.check2 = 'mdi-check-circle';
            this.color2 = 'primary';
          }
          if (checkList.kaiRecom) {
            this.check7 = 'mdi-check-circle';
            this.color7 = 'primary';
          }
          if (checkList.kaiApp) {
            this.check8 = 'mdi-check-circle';
            this.color8 = 'primary';
          }
          if (checkList.kaiKeyword) {
            this.check9 = 'mdi-check-circle';
            this.color9 = 'primary';
          }
          if (checkList.kaiTop) {
            this.check10 = 'mdi-check-circle';
            this.color10 = 'primary';
          }

          if (checkList.createCampaign) {
            this.check16 = 'mdi-check-circle';
            this.color16 = 'primary';
          }
          if (checkList.createCampaign) {
            this.check12 = 'mdi-check-circle';
            this.color12 = 'primary';
          }
          if (checkList.createAdgroup || checkList.createCampaign) {
            this.check13 = 'mdi-check-circle';
            this.color13 = 'primary';
          }
          if (checkList.addKeys || checkList.createCampaign) {
            this.check14 = 'mdi-check-circle';
            this.color14 = 'primary';
          }
          if (checkList.addCS) {
            this.check15 = 'mdi-check-circle';
            this.color15 = 'primary';
          }
          if (checkList.createCampaign && checkList.adsManager) {
            this.check3 = 'mdi-check-circle';
            this.color3 = 'primary';
          }
          if (checkList.createCampaign && checkList.adsManager) {
            this.check5 = 'mdi-check-circle';
            this.color5 = 'primary';
          }
          if (checkList.automationRules && checkList.createRule) {
            this.check4 = 'mdi-check-circle';
            this.color4 = 'primary';
          }
        }
      },
    },
  },
  methods: {
    customFilter(value, search, item) {
      const searchTerm = search.toLowerCase();
      const searchableFields = this.memberheaders.map(header => header.value);
      searchableFields.push('Email');

      return searchableFields.some(field => {
        if (item[field] && typeof item[field] === 'string') {
          return item[field].toLowerCase().includes(searchTerm);
        }
        return false;
      });
    },
    isItemEnabled(item) {
      return ['active', 'in_trial'].includes(item.Status);
    },
    openDeleteIntegrationDialog(id, type) {
      const TeamID = this.selectedTeamId;
      this.integrationData = { id: id, TeamID: TeamID, type: type, now: Date.now() };
    },
    aiCreditsActionDone() {
      this.allMemberDetails();
      this.getGlobalAiCredits();
    },

    changeCreditsPopup(user) {
      this.userCreditsData = {
        TeamID: user.TeamID,
        usageLimit: user.aiCredits.usageLimit,
        mode: 'individual',
        now: Date.now(),
      };
    },
    changeCreditsGloballyPopup() {
      this.userCreditsData = {
        usageLimit: this.globalAiCreditsLimit,
        mode: 'global',
        now: Date.now(),
      };
    },
    async getBLDomains() {
      const blDomains = await SignUpService.getBLDomains();
      this.blDomains = blDomains;
    },
    async getSS() {
      const enableSS = await SignUpService.getSS();
      this.enableSS = enableSS;
    },
    copyRecoveryLink() {
      navigator.clipboard.writeText(this.resetLink);
      this.$notify.success('Recovery link Copied');
    },
    generateResetPasswordLink(item) {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: GENERATE_REST_PASSWORD_LINK,
          variables: {
            userId: item.UserId,
          },
        })
        .then((res) => {
          this.fullscreenLoader.hide();
          this.resetLinkDialog = true;
          if (res.data.generateResetPasswordLink.data != '') {
            this.resetLink = res.data.generateResetPasswordLink.data;
            this.resetLinkMsg = `The user, ${item.Email}, can use the above link to set a new password. This link should be used in incognito/private browser window`;
          } else {
            this.resetLink = '';
            this.resetLinkMsg = `Unable to generate link for user ${item.Email}. Please try later`;
          }
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
          this.$notify.error('Something went wrong. Please try later');
        });
    },
    resetCheckList() {
      this.check0 = 'mdi-checkbox-blank-circle-outline';
      this.check1 = 'mdi-checkbox-blank-circle-outline';
      this.check2 = 'mdi-checkbox-blank-circle-outline';
      this.check3 = 'mdi-checkbox-blank-circle-outline';
      this.check4 = 'mdi-checkbox-blank-circle-outline';
      this.check5 = 'mdi-checkbox-blank-circle-outline';
      this.check6 = 'mdi-checkbox-blank-circle-outline';
      this.mmpIntCheck = 'mdi-checkbox-blank-circle-outline';
      this.createMmpGoalsCheck = 'mdi-checkbox-blank-circle-outline';
      (this.scheduledReportsCheck = 'mdi-checkbox-blank-circle-outline'),
        (this.campGenCheck = 'mdi-checkbox-blank-circle-outline'),
        (this.check7 = 'mdi-checkbox-blank-circle-outline');
      this.check8 = 'mdi-checkbox-blank-circle-outline';
      this.check9 = 'mdi-checkbox-blank-circle-outline';
      this.check10 = 'mdi-checkbox-blank-circle-outline';
      this.check11 = 'mdi-checkbox-blank-circle-outline';
      this.check12 = 'mdi-checkbox-blank-circle-outline';
      this.check13 = 'mdi-checkbox-blank-circle-outline';
      this.check14 = 'mdi-checkbox-blank-circle-outline';
      this.check15 = 'mdi-checkbox-blank-circle-outline';
      this.check16 = 'mdi-checkbox-blank-circle-outline';
      this.color0 = '';
      this.color1 = '';
      this.color7 = '';
      this.color2 = '';
      this.color6 = '';
      this.mmpIntColor = '';
      this.createMmpGoalsColor = '';
      this.color3 = '';
      this.color4 = '';
      this.color5 = '';
      this.color8 = '';
      this.color9 = '';
      this.color10 = '';
      this.color11 = '';
      this.color12 = '';
      this.color13 = '';
      this.color14 = '';
      this.color15 = '';
      this.color16 = '';
    },
    unixToLocal(unix) {
      return moment.unix(unix).format('YYYY-MM-DD');
    },
    showCheckList() {
      this.showCL = ~this.showCL;
      if (this.showCL) {
        window.scrollTo(0, 0);
      }
    },
    askConfirmation(ctx, data) {
      this.decisionAlertFlag = true;
      if (ctx === 1) this.decisionAlertTitle = 'Are you sure you want to end subscription?';
      if (ctx === 2) {
        this.onAutomationV2CurrVal = data.onAutomationV2;
        this.onAutomationV2TeamID = data.TeamID;
        if (!data.onAutomationV2) this.decisionAlertTitle = `Are you sure you want to switch ${data.CompanyName}'s automation back to V1?`;
        if (data.onAutomationV2) this.decisionAlertTitle = `Are you sure you want to switch ${data.CompanyName}'s automation to V2?`;
      }
      this.decisionAlertData = {
        now: Date.now(),
        from: 'USERS',
        ctx,
        data,
      };
    },
    async decisionOkAction(event) {
      this.decisionAlertFlag = false;
      if (event.from === 'USERS' && event.ctx === 1) await this.endSubscription();
      if (event.from === 'USERS' && event.ctx === 2) this.changeOnAutomationV2(event.data);
    },
    clearDecisionModel() {
      this.decisionAlertFlag = false;
      this.tempCompaniesList = this.companiesList.map((c) => {
        if (c.TeamID === this.onAutomationV2TeamID) {
          return {
            ...c,
            onAutomationV2: this.onAutomationV2CurrVal ? false : true,
          };
        } else return c;
      });
      this.companiesList = this.tempCompaniesList;
    },
    async endSubscription() {
      try {
        const result = await this.$apollo.query({
          query: END_SUBSCRIPTION,
          variables: {
            subId: this.sub_id,
          },
        });
        if (result.data.endSubscription.Status == 'cancelled') {
          this.subStatus = 'cancelled';
          this.$notify.success('Subscription Cancelled');
        }
      } catch (err) {
        this.$notify.error('Something went wrong');
      }
    },

    async startSync(teamId: string, firstSync: boolean) {
      this.syncing = true;
      try {
        const result = await this.$apollo.query({
          query: TRIGGER_MANUAL_SYNC,
          variables: {
            teamId: teamId,
            firstSync: firstSync,
          },
        });

        if (result.data.invokeManualSync.successMessage) {
          this.$notify.success(firstSync ? 'First sync started for the user!' : 'Daily sync started for the user!');
        }

        this.syncing = false;
      } catch (err) {
        console.log(err);
      }
    },

    setChartData(data, propName): void {
      data.datasets.map((item) => {
        item.label = _.startCase(item.label);
        item.fill = false;
        item.borderColor = this.getRandomColor();
        item.borderWidth = 1;
      });
      data.appList = this.appsReport?.row;
      this[propName] = { ...data, time: Date.now().toString() };
    },

    setAppsReportData(data) {
      this.appsReportCount = this.appsReport.row.length;
      this.totalSpend = data.appsReportsForCompany.grandTotals.total.localSpend.amount;
      this.totalTapInstalls = data.appsReportsForCompany.grandTotals.total.tapInstalls;
    },

    getRandomColor() {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    async getGlobalAiCredits() {
      try {
        const result = await this.$apollo.query({
          query: GET_GLOBAL_AI_CREDITS,
          variables: {},
        });

        this.globalAiCreditsLimit = result.data.getGlobalCredits.globalLimit;
      } catch (error) {
        console.log(error);
      }
    },

    allMemberDetails: async function () {
      this.fullscreenLoader = this.$loading.show();
      this.memberDetails = [];
      await this.$apollo
        .query({
          query: ALL_USERS,
          variables: {},
        })
        .then((res) => {
          this.memberDetails = [];
          this.memberDetails = res.data.allUsers;
          this.memberDetails = this.memberDetails.sort((a, b) => new Date(b.CreatedOn).getTime() - new Date(a.CreatedOn).getTime());
          this.memberDetails = UserService.keepOneUserPerCompany(this.memberDetails);
          const len = this.memberDetails.length;
          for (let index = 0; index < len; index++) {
            const el = this.memberDetails[index];
            this.memberDetails[index].CreatedOn = el.CreatedOn.toString().substr(0, 10);
            this.memberDetails[index].IsEnabled = !this.memberDetails[index].IsDisabled;
          }

          this.activeCompanies = this.memberDetails.filter((member) => member.IsDeleted == false);
          this.deletedCompanies = this.memberDetails.filter((member) => member.IsDeleted == true);
          this.disabledCompanies = this.memberDetails.filter((member) => member.IsDisabled == true);
          this.companiesList = this.memberDetails;
          this.fullscreenLoader.hide();
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
        });
    },
    closeInvitePopup() {
      this.inviteDialog = false;
      this.emailForInvite = null;
    },
    reInviteMember: async function (data) {
      this.loading = true;
      await this.$apollo
        .query({
          query: RE_INVITE_CA,
          variables: {
            userId: data.UserId,
            email: data.Email,
            change: Date.now(),
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.data.reInviteCA.code === 0) {
            this.msgModel = true;
            this.msgTitle = 'Success!';
            this.message = 'Invite Link Sent';
          } else {
            this.msgModel = true;
            this.msgTitle = 'Error!!!';
            this.message = 'Something Went Wrong.';
          }
        })
        .catch((error) => {
          this.loading = false;
          this.msgModel = true;
          this.msgTitle = 'Error!!!';
          this.message = 'Something Went Wrong.';
        });
    },
    changeMemberStatus: async function (event, item) {
      const newStatus = event ? 'active' : 'cancelled';
      try {
        await this.$apollo
          .mutate({
            mutation: UPDATE_SUBSCRIPTION,
            variables: {
              subId: item.TeamID,
              status: newStatus, // e.g active in_trial cancelled
            },
          })
          .then((res) => {
            this._handleSuccessChangeMemberStatus(item, newStatus);
          })
          .catch((error) => {
            this.$notify.error('Error while changing company status');
          });
      } catch (error) {
        console.log(error);
      }
    },
    _handleSuccessChangeMemberStatus(item, newStatus) {
      item.Status = newStatus;
      let msg = '';
      if (newStatus === 'active') msg = 'Company Subscription Enabled Successfully';
      if (newStatus === 'cancelled') msg = 'Company Subscription Disabled Successfully';
      this.$notify.success(msg);
    },
    changeOnAutomationV2: async function (data) {
      this.loading = true;
      await this.$apollo
        .query({
          query: TOGGLE_AUTOMATION_V2,
          variables: {
            teamId: data.TeamID,
            at: Date.now(),
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.data.toggleAutomationV2.code === 1) {
            this.msgModel = true;
            this.msgTitle = 'Success!';
            this.message = `Automation switched to the new V2 for ${data.CompanyName}`;
          } else if (res.data.toggleAutomationV2.code === 2) {
            this.msgModel = true;
            this.msgTitle = 'Success!';
            this.message = `Automation switched back to V1 for ${data.CompanyName}`;
          } else {
            this.msgModel = true;
            this.msgTitle = 'Error!!!';
            this.message = `${data.CompanyName} - ${res.data.toggleAutomationV2.successMessage}`;
          }
        })
        .catch((error) => {
          this.loading = false;
          this.msgModel = true;
          this.msgTitle = 'Error!!!';
          this.message = `Something Went Wrong while switching version`;
        });
    },
    async createUser() {
      this.fullscreenLoader = this.$loading.show();
      const finalEmail = this.createUserEmail.toLowerCase();
      await SignUpService.createUser({
        schema_id: 'default',
        traits: {
          email: finalEmail.trim(),
        },
      })
        .then((res) => {
          this.fullscreenLoader.hide();
          this.createUserDialog = false;
          if (res.code == 0) {
            this.$notify.success(this.$t('successMsg.userCreateMsg'));
            window.location.reload();
          } else if (res.code == 1) {
            this.$notify.error(this.$t('successMsg.emailExistMsg'));
          } else if (res.code == 2) {
            this.$notify.error('successMsg.errorCreateUser');
          } else if (res.code == 3) {
            this.$notify.error(this.$t('successMsg.inviteFailMsg'));
          } else {
            this.$notify.error(this.$t('successMsg.failTryLaterMsg'));
          }
        })
        .catch((err) => {
          this.fullscreenLoader.hide();
          this.createUserDialog = false;
          this.$notify.error(this.$t('successMsg.failTryLaterMsg'));
        });
    },

    closeDetailDialog() {
      this.detailDialog = false;
    },

    loggedinUserReload() {
      window.location.reload();
    },

    async openSwitchAccountDialog(userData) {
      console.log(userData);
      this.switchUserMetadata = {
        now: Date.now(),
        item: userData,
      };
    },

    async openDetailDialog(item) {
      this.deleteTrialCheck = false;
      this.invoiceCheck = false;
      this.trailEndDateInFuture = false;
      this.selectedTeamId = item.TeamID;
      this.selectedUserId = item.UserId;
      await this.subscriptionDetailsFetch(item.TeamID);
      this.showCL = false;
      this.resetCheckList();
      this.$apollo.queries.getLoginActivities.refetch();
      this.$apollo.queries.getCheckList.refetch();
      this.detailDialog = true;
    },

    async filterCampApp(adamIds) {
      for (let i = 0; i < adamIds.length; i++) {
        let j = 0;
        let appArray = [];
        for (let k = 0; k < this.campaignReport.row.length; k++) {
          if (this.campaignReport.row[k].metadata.app.adamId == adamIds[i]) {
            j = j + 1;
          }
        }
        this.totalCampInApp.push(appArray);
        appArray.push({ appId: adamIds[i], totalNoCamp: j });
      }

      for (let a = 0; a < this.appsReport.row.length; a++) {
        for (let b = 0; b < this.totalCampInApp.length; b++) {
          if (this.appsReport.row[a].metadata.app.adamId == this.totalCampInApp[b][0].appId) {
            this.appsReport.row[b].total['totalCampInApp'] = this.totalCampInApp[b][0].totalNoCamp;
          }
        }
      }
      this.totalCampInApp = [];
      this.appsReportFinal = this.appsReport;
    },

    async campaignReportsFetch() {
      await this.$apollo
        .query({
          query: CAMPAIGN_REPORTS_FOR_COMPANY,
          variables: {
            TeamID: this.selectedTeamId,
            reportDateRange: this.reportDateRange,
          },
        })
        .then((res) => {
          this.campaignReport = res.data.campaignReportsForCompany;
          this.fullscreenLoader.hide();
          const adamIds = _.uniq(this.campaignReport.row.map((data) => data.metadata.app.adamId));
          this.filterCampApp(adamIds);
          this.liveCampaignReport('');
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
        });
    },

    subscriptionDetailsFetch(custId) {
      this.$apollo
        .query({
          query: CB_Subs,
          variables: {
            custId: custId,
          },
        })
        .then((data) => {
          this.subscriptionDataCal(data.data.getSubsForCust.list[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    subscriptionDataCal(subData) {
      this.trailEndDate = subData.TrailEnd.split('T')[0];
      const today = moment().utc().format('YYYY-MM-DD');
      const deleteTrialPeriod = moment().utc().subtract(30, 'days').format('YYYY-MM-DD');

      if (this.trailEndDate > today) {
        this.trailEndDateInFuture = true;
      }

      if (this.trailEndDate < deleteTrialPeriod) {
        this.deleteTrialCheck = true;
      }

      this.sub_id = subData.TeamID;
      this.subStatus = subData.Status;
      this.subActivatedDate = subData.ActivatedOn.split('T')[0];
    },

    async setEndTrailDate() {
      this.datePicker = false;
      this.newTrialEndDate = this.newTrialEndDate + ' 23:59:59';
      const date = momentTz.tz(this.newTrialEndDate, 'YYYY-MM-DD hh:mm:ss').unix();
      await this.$apollo
        .query({
          query: CHANGE_TERM_END,
          variables: {
            sub_id: this.sub_id,
            termEndDate: date.toString(),
          },
        })
        .then(async (res) => {
          await this.updateTrailActivity(res.data.changeTermEndPlan);
          this.subscriptionDetailsFetch(res.data.changeTermEndPlan.TeamID);
          this.$notify.success('Trial end date changed');
        })
        .catch((error) => {
          this.$notify.error('Error while changing trial date');
        });
    },

    async updateTrailActivity(data) {
      const trialActivityData = {
        TeamID: this.selectedTeamId,
        updatedAt: moment.unix(data.UpdatedOn).format('YYYY-MM-DD'),
        updatedBy: decodeToken(LSS.token).FirstName, //decodeToken(LSS.token).LastName
        lastTrialEndDate: this.trailEndDate,
        UpdatedTrialEndDate: moment.unix(data.TrailEnd).format('YYYY-MM-DD'),
      };

      await this.$apollo
        .mutate({
          mutation: UPDATE_TRIAL_ACTIVITY,
          variables: {
            trialActivityReq: trialActivityData,
          },
        })
        .then((res) => {
          this.$apollo.queries.getTrailActivity.refetch();
        })
        .catch((error) => {});
    },

    async appsGraphFetch(selectedApp, chartGranularity) {
      await this.$apollo
        .query({
          query: APPS_GRAPH_FOR_COMPANY,
          variables: {
            graphFilter: {
              adamID: selectedApp,
              TeamID: this.selectedTeamId,
              granularity: chartGranularity,
              dateRange: this.filterDateRange,
            },
          },
        })
        .then((res) => {
          this.setChartData(res.data.appsGraphForCompany, 'appsChartData');
        })
        .catch((error) => {});
    },

    setCompanyMemberDetails(data) {
      const allMembers = data;
      this.companyAdmin = allMembers.filter((item) => item.Role == Roles.ADMIN);
      if (this.companyAdmin.length == 0) {
        this.companyAdmin = allMembers.filter((item) => item.Role == Roles.SUPERADMIN);
      }
      this.companyStaff = allMembers.filter((item) => item.Role != Roles.SUPERADMIN);
    },

    liveCampaignReport(key) {
      this.liveCampaignReports = this.campaignReport;
      let tempCampaignReports = [];
      for (const element of this.liveCampaignReports.row) {
        if (key != '' && element.campaignMetadata.status == 'ENABLED' && !element.campaignMetadata.deleted) {
          if (element.campaignMetadata.name.toLowerCase().includes(key.toLowerCase())) {
            tempCampaignReports.push(element);
          }
        } else if (element.campaignMetadata.status == 'ENABLED' && !element.campaignMetadata.deleted) {
          tempCampaignReports.push(element);
        }
      }
      let campaignReportsSchema = {
        grandTotals: this.liveCampaignReports.grandTotals,
        row: tempCampaignReports,
      };
      this.liveReportsCount = tempCampaignReports.length;
      this.liveCampaignReports = campaignReportsSchema;
    },

    handleGranularityChange(selectedApp, chartGranularity: string): void {
      this.granularity = chartGranularity;
      this.appsGraphFetch(selectedApp, chartGranularity);
    },

    handleAppChange(selectedApp, chartGranularity) {
      this.selectedApp = selectedApp;
      this.appsGraphFetch(selectedApp, chartGranularity);
    },

    applyDateRange() {
      this.filterDateRange = this.filterDateRangeModel;
      // persisting filters that are already applied
      this.appsGraphFetch(this.selectedApp, this.granularity);
      this.rangePicker = false;
    },

    deleteCompany() {
      this.companyDeletionMetadata = {
        TeamID: this.selectedTeamId,
      };
    },

    archiveStaffMember(data) {
      this.archiveStaffMemberData = {
        email: data.Email,
        now: Date.now(),
      };
    },

    deleteSmokeTestUsers() {
      this.testUsersMetadata = {
        now: Date.now(),
      };
    },

    removeUser() {
      this.removeUserMetadata = {
        now: Date.now(),
        Email: this.removeUserEmail,
      };
    },

    onRemoveUser() {
      this.removeUserDialog = false;
      this.removeUserEmail = null;
      window.location.reload();
    },

    onUpdate() {
      this.detailDialog = false;
      window.location.reload();
    },

    onArchiveStaff() {
      this.$apollo.queries.companyDetails.refetch();
    },

    changeCompaniesList() {
      if (this.selectedCompanies.value == 'all') {
        this.companiesList = this.memberDetails;
      } else if (this.selectedCompanies.value == 'active') {
        this.companiesList = this.activeCompanies;
      } else if (this.selectedCompanies.value == 'disabled') {
        this.companiesList = this.disabledCompanies;
      } else if (this.selectedCompanies.value == 'deleted') {
        this.companiesList = this.deletedCompanies;
      }
    },
    toggleSelfSignup(state) {
      this.fullscreenLoader = this.$loading.show();
      this.$apollo
        .query({
          query: TOGGLE_SIGNUP,
          variables: {
            state: state,
            at: Date.now(),
          },
        })
        .then((res) => {
          if (res.data.toggleSelfSignUp && res.data.toggleSelfSignUp.code === 0) {
            const s = this.enableSS ? 'Off' : 'On';
            this.enableSS = !this.enableSS;
            this.$notify.success(`Self sign up turned ${s} successfully`);
          }
          this.fullscreenLoader.hide();
        })
        .catch((error) => {
          this.fullscreenLoader.hide();
          this.$notify.error('Something went wrong. Please try later');
        });
    },

    usageDialogFn(data) {
      this.usageDialog = true;
      this.loginActivityDetail = [...data];
    },
  },
  mounted() {
    if (LSS.adminToken) return this.$router.push('/overview');
    this.getBLDomains();
    this.syncStatus = LSS.activeSync;
    Vue.prototype.$sckt.on('syncprogress:event', () => {
      this.syncStatus = LSS.activeSync;
    });
    this.locale = localStorage.getItem('locale') || 'en';
    this.allMemberDetails();
    this.getGlobalAiCredits();
    this.$root.$on('locale-changed', (locale) => {
      this.locale = locale.localeCode;
      this.$refs.createUserFormAdmin.validate();
    });
    this.getSS();

    this.$root.$on('asaIntegrationDeleted', async () => {
      await this.$apollo.queries.getIntegrationForCompany.refetch();
    });
  },
});
