var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dashboard" },
    [
      _c(
        "v-app",
        [
          _c(
            "div",
            { staticClass: "inner-container only-heading-part" },
            [
              _c(
                "div",
                {
                  staticClass: "top-page-header page-header-with-banner",
                  attrs: { lang: _vm.locale },
                },
                [
                  _c("div", { staticClass: "head-title top-space" }, [
                    _c("div", {
                      staticClass: "top-heading-left",
                      class: _vm.syncStatus ? "syncActive" : "",
                    }),
                    _c("div", { staticClass: "top-heading-right" }),
                  ]),
                ]
              ),
              _c(
                "v-container",
                {
                  staticClass:
                    "v-main-content stepper-template only-simple-content",
                  attrs: { fluid: "" },
                },
                [
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "pa-4 rounded-lg extra-padding",
                        attrs: { elevation: "3" },
                      },
                      [
                        _c("v-card-text", { staticClass: "px-0 pt-0 pb-0" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "sub-heading-with-btn d-flex flex-wrap align-start",
                            },
                            [
                              _c(
                                "h3",
                                { staticClass: "black-text" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("users.manageComp")) +
                                      " "
                                  ),
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: {
                                        bottom: "",
                                        color: "primarydark",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "activator",
                                          fn: function (ref) {
                                            var on = ref.on
                                            var attrs = ref.attrs
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "greyxdark--text",
                                                    },
                                                    "v-icon",
                                                    attrs,
                                                    false
                                                  ),
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "mdi-information-outline"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ]),
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("users.addDisManageComp")
                                          )
                                        ),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "btn-select-wrap d-flex align-center flex-wrap justify-end",
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0",
                                      attrs: {
                                        cols: "12",
                                        lg: "4",
                                        md: "4",
                                        sm: "6",
                                      },
                                    },
                                    [
                                      _c("v-text-field", {
                                        staticClass: "mt-0 pt-0 mx-2",
                                        attrs: {
                                          width: "200",
                                          "append-icon": "mdi-magnify",
                                          label: _vm.$t("labels.search"),
                                          "hide-details": "",
                                        },
                                        model: {
                                          value: _vm.search,
                                          callback: function ($$v) {
                                            _vm.search = $$v
                                          },
                                          expression: "search",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pb-0",
                                      attrs: {
                                        cols: "12",
                                        lg: "4",
                                        md: "4",
                                        sm: "6",
                                      },
                                    },
                                    [
                                      _c("v-select", {
                                        staticClass: "mx-2 mt-3",
                                        attrs: {
                                          items: _vm.companies,
                                          "item-text": "text",
                                          "item-value": "value",
                                          attach: "",
                                          dense: "",
                                          outlined: "",
                                          "return-object": "",
                                        },
                                        on: { change: _vm.changeCompaniesList },
                                        model: {
                                          value: _vm.selectedCompanies,
                                          callback: function ($$v) {
                                            _vm.selectedCompanies = $$v
                                          },
                                          expression: "selectedCompanies",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "dataTable mt-0" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex align-center justify-space-between mb-3 flex-wrap",
                              },
                              [
                                _c("p", { staticClass: "text-para-heading" }, [
                                  _vm._v(_vm._s(_vm.$t("users.allCompAdmin"))),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "v-search-with-btn w-md-100" },
                                  [
                                    _c(
                                      "v-dialog",
                                      {
                                        attrs: { width: "500", persistent: "" },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function (ref) {
                                              var on = ref.on
                                              var attrs = ref.attrs
                                              return [
                                                _c(
                                                  "v-btn",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        staticClass: "ml-4",
                                                        attrs: {
                                                          color: "primary",
                                                          depressed: "",
                                                        },
                                                      },
                                                      "v-btn",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v(" Remove user ")]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                        model: {
                                          value: _vm.removeUserDialog,
                                          callback: function ($$v) {
                                            _vm.removeUserDialog = $$v
                                          },
                                          expression: "removeUserDialog",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-card",
                                          {
                                            staticClass:
                                              "d-flex align-center justify-center flex-column",
                                          },
                                          [
                                            _c(
                                              "v-card-title",
                                              {
                                                staticClass:
                                                  "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3",
                                              },
                                              [
                                                _c("h5", [
                                                  _vm._v(
                                                    "Enter email for user to remove"
                                                  ),
                                                ]),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "white--text",
                                                    on: {
                                                      click: function ($event) {
                                                        _vm.removeUserDialog = false
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("mdi-close")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-card-text",
                                              {
                                                staticClass: "w-100 px-6 py-8",
                                              },
                                              [
                                                _c(
                                                  "v-form",
                                                  {
                                                    staticClass: "mb-0",
                                                    model: {
                                                      value:
                                                        _vm.removeUserValid,
                                                      callback: function ($$v) {
                                                        _vm.removeUserValid = $$v
                                                      },
                                                      expression:
                                                        "removeUserValid",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-row",
                                                      { staticClass: "mt-0" },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            staticClass: "py-0",
                                                            attrs: {
                                                              cols: "12",
                                                              sm: "12",
                                                            },
                                                          },
                                                          [
                                                            _c("v-text-field", {
                                                              attrs: {
                                                                placeholder: _vm.$t(
                                                                  "users.enterEmail"
                                                                ),
                                                                required: "",
                                                                outlined: "",
                                                                dense: "",
                                                                rules:
                                                                  _vm.removeEmailRules,
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm.removeUserEmail,
                                                                callback: function (
                                                                  $$v
                                                                ) {
                                                                  _vm.removeUserEmail = $$v
                                                                },
                                                                expression:
                                                                  "removeUserEmail",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-card-actions",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-end align-center w-100 pb-0 pt-0 px-0",
                                                      },
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass: " ",
                                                            attrs: {
                                                              width: "60px",
                                                              color: "primary",
                                                              depressed: "",
                                                              disabled: !_vm.removeUserValid,
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.removeUser,
                                                            },
                                                          },
                                                          [_vm._v("Ok")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-3",
                                        attrs: { color: "primary", dark: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteSmokeTestUsers()
                                          },
                                        },
                                      },
                                      [_vm._v(" Delete All Smoke Test Users ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-3",
                                        attrs: { color: "primary", dark: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleSelfSignup(
                                              !_vm.enableSS
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm.enableSS
                                          ? _c("span", [
                                              _vm._v(" Turn off self sign up "),
                                            ])
                                          : _c("span", [
                                              _vm._v(" Turn on self sign up "),
                                            ]),
                                      ]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        staticClass: "mr-3",
                                        attrs: { color: "primary", dark: "" },
                                        on: {
                                          click: _vm.changeCreditsGloballyPopup,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          " Change AI Credits (All Users) "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c("SwitchUserAccount", {
                              attrs: {
                                detailsForSwitchUser: _vm.switchUserMetadata,
                              },
                              on: {
                                onSwitchUser: function ($event) {
                                  return _vm.loggedinUserReload()
                                },
                              },
                            }),
                            _c("v-data-table", {
                              attrs: {
                                headers: _vm.memberheaders,
                                items: _vm.companiesList,
                                search: _vm.search,
                                "custom-filter": _vm.customFilter,
                                "no-data-text": _vm.$t("kai.showDataText"),
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "header.IsDisabled",
                                    fn: function (ref) {
                                      var header = ref.header
                                      return [
                                        _c("span", {
                                          domProps: {
                                            innerHTML: _vm._s(header.text),
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.IsDisabled",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        item.InviteStatus === "ACCEPTED"
                                          ? _c("v-switch", {
                                              staticClass: "borderedSwitch",
                                              attrs: {
                                                color: "primary",
                                                "input-value": _vm.isItemEnabled(
                                                  item
                                                ),
                                                inset: "",
                                                outlined: "",
                                              },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.changeMemberStatus(
                                                    $event,
                                                    item
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.onAutomationV2",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        item.InviteStatus === "ACCEPTED"
                                          ? _c("v-switch", {
                                              staticClass: "borderedSwitch",
                                              attrs: {
                                                color: "primary",
                                                inset: "",
                                                outlined: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.askConfirmation(
                                                    2,
                                                    item
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.onAutomationV2,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "onAutomationV2",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "item.onAutomationV2",
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.InviteStatus",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _vm._v(
                                          " " + _vm._s(item.InviteStatus) + " "
                                        ),
                                        item.InviteStatus === "PENDING"
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticClass:
                                                  "icon-hover p-0 reinvite-icon",
                                                attrs: {
                                                  title: "Resend Invite",
                                                  small: "",
                                                  color: "primary",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.reInviteMember(
                                                      item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " mdi-email-arrow-right-outline "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("br"),
                                        !item.CompanyName
                                          ? _c("span", [
                                              _vm._v(
                                                "Email : " + _vm._s(item.Email)
                                              ),
                                            ])
                                          : _vm._e(),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.CompanyName",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _c("p", [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openDetailDialog(
                                                    item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(item.CompanyName) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.Sync",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mr-2 my-2",
                                            attrs: {
                                              small: "",
                                              color: "primary",
                                              disabled: _vm.syncing,
                                              dark: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.startSync(
                                                  item.TeamID
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Daily ")]
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            staticClass: "mr-2 my-2",
                                            attrs: {
                                              small: "",
                                              color: "primary",
                                              disabled: _vm.syncing,
                                              dark: "",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.startSync(
                                                  item.TeamID,
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" First ")]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.SwitchAccount",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              bottom: "",
                                              color: "primarydark",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "a",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.openSwitchAccountDialog(
                                                                    item
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            "a",
                                                            _vm.attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "primary--text icon-hover",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-login-variant "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Login Company"),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "item.credits",
                                    fn: function (ref) {
                                      var item = ref.item
                                      return [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              bottom: "",
                                              color: "primarydark",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function (ref) {
                                                    var on = ref.on
                                                    return [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.aiCredits
                                                              .remainingUsage
                                                          ) +
                                                          " / " +
                                                          _vm._s(
                                                            item.aiCredits
                                                              .usageLimit
                                                          ) +
                                                          " "
                                                      ),
                                                      _c(
                                                        "a",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              on: {
                                                                click: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.changeCreditsPopup(
                                                                    item
                                                                  )
                                                                },
                                                              },
                                                            },
                                                            "a",
                                                            _vm.attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              staticClass:
                                                                "primary--text icon-hover",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " mdi-pencil-outline "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Change AI Credits limit"),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "v-dialog",
                {
                  attrs: { "hide-overlay": "", persistent: "", width: "300" },
                  model: {
                    value: _vm.loading,
                    callback: function ($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "primary white--text" },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("teamMgmt.processing")) + " "
                          ),
                        ]
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "cust" },
                        [
                          _c("v-progress-linear", {
                            staticClass: "mb-0",
                            attrs: { indeterminate: "", color: "primary" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "v-dialog",
                {
                  attrs: { width: "300", persistent: "" },
                  model: {
                    value: _vm.msgModel,
                    callback: function ($$v) {
                      _vm.msgModel = $$v
                    },
                    expression: "msgModel",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "primary white--text" },
                        [_vm._v(" " + _vm._s(_vm.msgTitle) + " ")]
                      ),
                      _c(
                        "v-card-text",
                        { staticClass: "text-subtitle-1 pt-3" },
                        [_vm._v(" " + _vm._s(_vm.message) + " ")]
                      ),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", text: "" },
                              on: {
                                click: function ($event) {
                                  _vm.msgModel = false
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("teamMgmt.ok")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-right" },
            [
              [
                _c(
                  "v-dialog",
                  {
                    attrs: { fullscreen: "", persistent: "" },
                    model: {
                      value: _vm.detailDialog,
                      callback: function ($$v) {
                        _vm.detailDialog = $$v
                      },
                      expression: "detailDialog",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "colFullOverview h-100",
                        class: { open: _vm.showCL },
                      },
                      [
                        _vm.existCL
                          ? _c(
                              "v-btn",
                              {
                                staticClass:
                                  "floatingBtn rounded-r-xl-0 rounded-l-xl",
                                attrs: {
                                  color: "primary",
                                  elevation: "0",
                                  dark: "",
                                  absolute: "",
                                  top: "",
                                  right: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showCheckList()
                                  },
                                },
                              },
                              [
                                _c("v-icon", { staticClass: "first-ic" }, [
                                  _vm._v("mdi-check-circle"),
                                ]),
                                _c("v-icon", { staticClass: "second-ic" }, [
                                  _vm._v("mdi-close-circle"),
                                ]),
                              ],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          { staticClass: "col-left-main" },
                          [
                            _c(
                              "v-card",
                              {
                                staticClass:
                                  "v-modal-fixed scrollx-hidden h-100",
                              },
                              [
                                _c(
                                  "v-card-title",
                                  {
                                    staticClass:
                                      "headline d-flex justify-space-between px-5 py-3 v-modal-header",
                                  },
                                  [
                                    _c("div"),
                                    _c(
                                      "v-icon",
                                      {
                                        staticClass: "black--text",
                                        on: {
                                          click: function ($event) {
                                            return _vm.closeDetailDialog()
                                          },
                                        },
                                      },
                                      [_vm._v("mdi-close")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "users-card" },
                                  [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass:
                                          "pa-2 profile-wrap-main h-100",
                                        attrs: { elevation: "0" },
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          {
                                            attrs: { justify: "space-between" },
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "profileContentWrapper",
                                                attrs: { cols: "12", md: "12" },
                                              },
                                              [
                                                _c("v-form", { ref: "form" }, [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "profile-bottom-content flex-column",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "company-profile d-flex justify-space-between flex-wrap align-center w-100 px-4 py-3",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex align-center",
                                                            },
                                                            [
                                                              _c(
                                                                "v-avatar",
                                                                {
                                                                  staticClass:
                                                                    "mr-3",
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    size: "60",
                                                                  },
                                                                },
                                                                [
                                                                  _c("v-img", {
                                                                    staticClass:
                                                                      "ma-0",
                                                                    attrs: {
                                                                      rounded:
                                                                        "",
                                                                      src:
                                                                        _vm.selectedCompanyImage,
                                                                    },
                                                                  }),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "h5",
                                                                {
                                                                  staticClass:
                                                                    "text-h6",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Company : "
                                                                  ),
                                                                  _c("b", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .companyAdmin[0]
                                                                          .CompanyName
                                                                      )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "profile-details d-flex align-center mr-16 ml-6",
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex flex-column",
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Status "
                                                                    ),
                                                                  ]),
                                                                  !_vm
                                                                    .companyAdmin[0]
                                                                    .IsDisabled
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "primary--text",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Active"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm
                                                                    .companyAdmin[0]
                                                                    .IsDisabled
                                                                    ? _c(
                                                                        "span",
                                                                        {
                                                                          staticClass:
                                                                            "error--text",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Paused"
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              _c("v-divider", {
                                                                staticClass:
                                                                  "mx-16",
                                                                attrs: {
                                                                  vertical: "",
                                                                },
                                                              }),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex flex-column",
                                                                },
                                                                [
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      "Account Created On "
                                                                    ),
                                                                  ]),
                                                                  _c("span", [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.companyAdmin[0].CreatedOn.substr(
                                                                            0,
                                                                            10
                                                                          )
                                                                        )
                                                                    ),
                                                                  ]),
                                                                ]
                                                              ),
                                                              _vm.subStatus ==
                                                              "active"
                                                                ? _c(
                                                                    "v-divider",
                                                                    {
                                                                      staticClass:
                                                                        "mx-16",
                                                                      attrs: {
                                                                        vertical:
                                                                          "",
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _vm.subStatus ==
                                                              "active"
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex flex-column",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Subscription Activated On "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.subActivatedDate
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                              _vm.subStatus ==
                                                              "active"
                                                                ? _c(
                                                                    "v-divider",
                                                                    {
                                                                      staticClass:
                                                                        "mx-16",
                                                                      attrs: {
                                                                        vertical:
                                                                          "",
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                              _vm.subStatus ==
                                                              "active"
                                                                ? _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "d-flex flex-column",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            "Total Staff "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "span",
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm
                                                                                  .companyStaff
                                                                                  .length
                                                                              )
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ]
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "profile-content-top trail pa-4 pb-0 mb-0 w-100",
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "trial-inner-text d-flex align-center justify-space-between flex-wrap",
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    lg: "6",
                                                                    md: "6",
                                                                    sm: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _vm.trailEndDateInFuture
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "icon d-flex mt-3",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "svg",
                                                                            {
                                                                              attrs: {
                                                                                width:
                                                                                  "22",
                                                                                height:
                                                                                  "19",
                                                                                viewBox:
                                                                                  "0 0 22 19",
                                                                                fill:
                                                                                  "none",
                                                                                xmlns:
                                                                                  "http://www.w3.org/2000/svg",
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "path",
                                                                                {
                                                                                  attrs: {
                                                                                    d:
                                                                                      "M0 19H22L11 0L0 19ZM12 16H10V14H12V16ZM12 12H10V8H12V12Z",
                                                                                    fill:
                                                                                      "#F1B809",
                                                                                  },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _vm.trailEndDateInFuture
                                                                            ? _c(
                                                                                "p",
                                                                                {
                                                                                  staticClass:
                                                                                    "ml-2",
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    "Trial period is going to be expired soon."
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            : _vm._e(),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  staticClass:
                                                                    "trial-right-col",
                                                                  attrs: {
                                                                    cols: "12",
                                                                    lg: "6",
                                                                    md: "6",
                                                                    sm: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "bottom-col",
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "p",
                                                                        {
                                                                          staticClass:
                                                                            "mx-2",
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Expiry date(UTC): "
                                                                          ),
                                                                          _c(
                                                                            "strong",
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  _vm.trailEndDate
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm.subStatus ==
                                                                      "in_trial"
                                                                        ? _c(
                                                                            "v-menu",
                                                                            {
                                                                              ref:
                                                                                "menu",
                                                                              attrs: {
                                                                                "close-on-content-click": false,
                                                                                "return-value":
                                                                                  _vm.datePicker,
                                                                                transition:
                                                                                  "scale-transition",
                                                                                "offset-y":
                                                                                  "",
                                                                                "min-width":
                                                                                  "auto",
                                                                              },
                                                                              on: {
                                                                                "update:returnValue": function (
                                                                                  $event
                                                                                ) {
                                                                                  _vm.datePicker = $event
                                                                                },
                                                                                "update:return-value": function (
                                                                                  $event
                                                                                ) {
                                                                                  _vm.datePicker = $event
                                                                                },
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "activator",
                                                                                    fn: function (
                                                                                      ref
                                                                                    ) {
                                                                                      var on =
                                                                                        ref.on
                                                                                      var attrs =
                                                                                        ref.attrs
                                                                                      return [
                                                                                        _c(
                                                                                          "v-btn",
                                                                                          _vm._g(
                                                                                            _vm._b(
                                                                                              {
                                                                                                staticClass:
                                                                                                  "pa-2",
                                                                                                attrs: {
                                                                                                  depressed:
                                                                                                    "",
                                                                                                  color:
                                                                                                    "primary",
                                                                                                },
                                                                                              },
                                                                                              "v-btn",
                                                                                              attrs,
                                                                                              false
                                                                                            ),
                                                                                            on
                                                                                          ),
                                                                                          [
                                                                                            _vm._v(
                                                                                              "Change Date"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                false,
                                                                                2198139781
                                                                              ),
                                                                              model: {
                                                                                value:
                                                                                  _vm.datePicker,
                                                                                callback: function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.datePicker = $$v
                                                                                },
                                                                                expression:
                                                                                  "datePicker",
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-date-picker",
                                                                                {
                                                                                  attrs: {
                                                                                    "no-title":
                                                                                      "",
                                                                                    scrollable:
                                                                                      "",
                                                                                    min:
                                                                                      _vm.minTrialEndDate,
                                                                                  },
                                                                                  model: {
                                                                                    value:
                                                                                      _vm.newTrialEndDate,
                                                                                    callback: function (
                                                                                      $$v
                                                                                    ) {
                                                                                      _vm.newTrialEndDate = $$v
                                                                                    },
                                                                                    expression:
                                                                                      "newTrialEndDate",
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs: {
                                                                                        text:
                                                                                          "",
                                                                                        color:
                                                                                          "grey",
                                                                                      },
                                                                                      on: {
                                                                                        click: function (
                                                                                          $event
                                                                                        ) {
                                                                                          _vm.datePicker = false
                                                                                        },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Cancel"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      attrs: {
                                                                                        text:
                                                                                          "",
                                                                                        color:
                                                                                          "primary",
                                                                                      },
                                                                                      on: {
                                                                                        click: function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.setEndTrailDate()
                                                                                        },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "OK"
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                            ],
                                                                            1
                                                                          )
                                                                        : _vm._e(),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "profile-content-right px-5",
                                                        },
                                                        [
                                                          _c(
                                                            "v-card",
                                                            {
                                                              staticClass:
                                                                "main-card",
                                                              attrs: {
                                                                elevation: "0",
                                                              },
                                                            },
                                                            [
                                                              _c("v-toolbar", {
                                                                attrs: {
                                                                  color:
                                                                    "default",
                                                                  dark: "",
                                                                  flat: "",
                                                                  prominent: "",
                                                                },
                                                                scopedSlots: _vm._u(
                                                                  [
                                                                    {
                                                                      key:
                                                                        "extension",
                                                                      fn: function () {
                                                                        return [
                                                                          _c(
                                                                            "v-tabs",
                                                                            {
                                                                              model: {
                                                                                value:
                                                                                  _vm.tabs,
                                                                                callback: function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.tabs = $$v
                                                                                },
                                                                                expression:
                                                                                  "tabs",
                                                                              },
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-tabs-slider"
                                                                              ),
                                                                              _c(
                                                                                "v-tab",
                                                                                {
                                                                                  staticClass:
                                                                                    "primary--text",
                                                                                  attrs: {
                                                                                    href:
                                                                                      "#overview",
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Overview "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "v-tab",
                                                                                {
                                                                                  staticClass:
                                                                                    "primary--text",
                                                                                  attrs: {
                                                                                    href:
                                                                                      "#account-integrated",
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Accounts Integrated "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "v-tab",
                                                                                {
                                                                                  staticClass:
                                                                                    "primary--text",
                                                                                  attrs: {
                                                                                    href:
                                                                                      "#apps",
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Apps "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "v-tab",
                                                                                {
                                                                                  staticClass:
                                                                                    "primary--text",
                                                                                  attrs: {
                                                                                    href:
                                                                                      "#campaigns",
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Campaigns "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "v-tab",
                                                                                {
                                                                                  staticClass:
                                                                                    "primary--text",
                                                                                  attrs: {
                                                                                    href:
                                                                                      "#staff",
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Staff "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "v-tab",
                                                                                {
                                                                                  staticClass:
                                                                                    "primary--text",
                                                                                  attrs: {
                                                                                    href:
                                                                                      "#usage",
                                                                                  },
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    " Usage "
                                                                                  ),
                                                                                ]
                                                                              ),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ]
                                                                      },
                                                                      proxy: true,
                                                                    },
                                                                  ]
                                                                ),
                                                              }),
                                                              _c(
                                                                "v-tabs-items",
                                                                {
                                                                  model: {
                                                                    value:
                                                                      _vm.tabs,
                                                                    callback: function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.tabs = $$v
                                                                    },
                                                                    expression:
                                                                      "tabs",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-tab-item",
                                                                    {
                                                                      attrs: {
                                                                        id:
                                                                          "overview",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-card",
                                                                        {
                                                                          staticClass:
                                                                            "main-card pb-6",
                                                                          attrs: {
                                                                            flat:
                                                                              "",
                                                                            elevation:
                                                                              "0",
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-card-text",
                                                                            [
                                                                              _c(
                                                                                "v-row",
                                                                                {
                                                                                  staticClass:
                                                                                    "mb-2",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-col",
                                                                                    {
                                                                                      staticClass:
                                                                                        "d-flex align-center",
                                                                                      attrs: {
                                                                                        cols:
                                                                                          "12",
                                                                                        lg:
                                                                                          "9",
                                                                                        md:
                                                                                          "8",
                                                                                        sm:
                                                                                          "6",
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "h2",
                                                                                        {
                                                                                          staticClass:
                                                                                            "text-h6 font-weight-medium ma-0",
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "Statistics"
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-col",
                                                                                    {
                                                                                      attrs: {
                                                                                        cols:
                                                                                          "12",
                                                                                        lg:
                                                                                          "3",
                                                                                        md:
                                                                                          "4",
                                                                                        sm:
                                                                                          "6",
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-menu",
                                                                                        {
                                                                                          ref:
                                                                                            "menu",
                                                                                          attrs: {
                                                                                            "close-on-content-click": false,
                                                                                            "return-value":
                                                                                              _vm.rangePicker,
                                                                                            transition:
                                                                                              "scale-transition",
                                                                                            "offset-y":
                                                                                              "",
                                                                                            "min-width":
                                                                                              "auto",
                                                                                          },
                                                                                          on: {
                                                                                            "update:returnValue": function (
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.rangePicker = $event
                                                                                            },
                                                                                            "update:return-value": function (
                                                                                              $event
                                                                                            ) {
                                                                                              _vm.rangePicker = $event
                                                                                            },
                                                                                          },
                                                                                          scopedSlots: _vm._u(
                                                                                            [
                                                                                              {
                                                                                                key:
                                                                                                  "activator",
                                                                                                fn: function (
                                                                                                  ref
                                                                                                ) {
                                                                                                  var on =
                                                                                                    ref.on
                                                                                                  var attrs =
                                                                                                    ref.attrs
                                                                                                  return [
                                                                                                    _c(
                                                                                                      "v-text-field",
                                                                                                      _vm._g(
                                                                                                        _vm._b(
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "v-date-picker-cs",
                                                                                                            staticStyle: {
                                                                                                              "font-size":
                                                                                                                "revert",
                                                                                                            },
                                                                                                            attrs: {
                                                                                                              "prepend-icon":
                                                                                                                "mdi-calendar",
                                                                                                              readonly:
                                                                                                                "",
                                                                                                            },
                                                                                                            model: {
                                                                                                              value:
                                                                                                                _vm.filterDateRangeText,
                                                                                                              callback: function (
                                                                                                                $$v
                                                                                                              ) {
                                                                                                                _vm.filterDateRangeText = $$v
                                                                                                              },
                                                                                                              expression:
                                                                                                                "filterDateRangeText",
                                                                                                            },
                                                                                                          },
                                                                                                          "v-text-field",
                                                                                                          attrs,
                                                                                                          false
                                                                                                        ),
                                                                                                        on
                                                                                                      )
                                                                                                    ),
                                                                                                  ]
                                                                                                },
                                                                                              },
                                                                                            ]
                                                                                          ),
                                                                                          model: {
                                                                                            value:
                                                                                              _vm.rangePicker,
                                                                                            callback: function (
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.rangePicker = $$v
                                                                                            },
                                                                                            expression:
                                                                                              "rangePicker",
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-date-picker",
                                                                                            {
                                                                                              attrs: {
                                                                                                "no-title":
                                                                                                  "",
                                                                                                scrollable:
                                                                                                  "",
                                                                                                range:
                                                                                                  "",
                                                                                              },
                                                                                              model: {
                                                                                                value:
                                                                                                  _vm.filterDateRangeModel,
                                                                                                callback: function (
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.filterDateRangeModel = $$v
                                                                                                },
                                                                                                expression:
                                                                                                  "filterDateRangeModel",
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    text:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "grey",
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      _vm.rangePicker = false
                                                                                                    },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Cancel"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    disabled:
                                                                                                      _vm.filterDateRangeModel &&
                                                                                                      _vm
                                                                                                        .filterDateRangeModel
                                                                                                        .length !==
                                                                                                        2,
                                                                                                    text:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "primary",
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.applyDateRange()
                                                                                                    },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "OK"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ],
                                                                                            1
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "div",
                                                                                {
                                                                                  staticClass:
                                                                                    "statistics-col",
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-row",
                                                                                    [
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          attrs: {
                                                                                            cols:
                                                                                              "12",
                                                                                            lg:
                                                                                              "2",
                                                                                            md:
                                                                                              "4",
                                                                                            sm:
                                                                                              "6",
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-card",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pa-4 stastists-box",
                                                                                              attrs: {
                                                                                                outlined:
                                                                                                  "",
                                                                                                tile:
                                                                                                  "",
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "p",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Total "
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "br"
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    "Integrated Accounts"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "number headline font-weight-bold",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        _vm.totalIntegration
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          attrs: {
                                                                                            cols:
                                                                                              "12",
                                                                                            lg:
                                                                                              "2",
                                                                                            md:
                                                                                              "4",
                                                                                            sm:
                                                                                              "6",
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-card",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pa-4 stastists-box",
                                                                                              attrs: {
                                                                                                outlined:
                                                                                                  "",
                                                                                                tile:
                                                                                                  "",
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "p",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Total "
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "br"
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    "Apps"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "number headline font-weight-bold",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        _vm.appsReportCount
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          attrs: {
                                                                                            cols:
                                                                                              "12",
                                                                                            lg:
                                                                                              "2",
                                                                                            md:
                                                                                              "4",
                                                                                            sm:
                                                                                              "6",
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-card",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pa-4 stastists-box",
                                                                                              attrs: {
                                                                                                outlined:
                                                                                                  "",
                                                                                                tile:
                                                                                                  "",
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "p",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Total Active Campaign"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "number headline font-weight-bold",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        _vm.liveReportsCount
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          attrs: {
                                                                                            cols:
                                                                                              "12",
                                                                                            lg:
                                                                                              "2",
                                                                                            md:
                                                                                              "4",
                                                                                            sm:
                                                                                              "6",
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-card",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pa-4 stastists-box",
                                                                                              attrs: {
                                                                                                outlined:
                                                                                                  "",
                                                                                                tile:
                                                                                                  "",
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "p",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Total Keywords"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "number headline font-weight-bold",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        _vm.keywordsCount
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          attrs: {
                                                                                            cols:
                                                                                              "12",
                                                                                            lg:
                                                                                              "2",
                                                                                            md:
                                                                                              "4",
                                                                                            sm:
                                                                                              "6",
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-card",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pa-4 stastists-box",
                                                                                              attrs: {
                                                                                                outlined:
                                                                                                  "",
                                                                                                tile:
                                                                                                  "",
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "p",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Total Amount Spend"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "number headline font-weight-bold",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        _vm.totalSpend.substr(
                                                                                                          0,
                                                                                                          6
                                                                                                        )
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-col",
                                                                                        {
                                                                                          attrs: {
                                                                                            cols:
                                                                                              "12",
                                                                                            lg:
                                                                                              "2",
                                                                                            md:
                                                                                              "4",
                                                                                            sm:
                                                                                              "6",
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-card",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pa-4 stastists-box",
                                                                                              attrs: {
                                                                                                outlined:
                                                                                                  "",
                                                                                                tile:
                                                                                                  "",
                                                                                              },
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "p",
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "Total "
                                                                                                  ),
                                                                                                  _c(
                                                                                                    "br"
                                                                                                  ),
                                                                                                  _vm._v(
                                                                                                    "TapInstalls"
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                              _c(
                                                                                                "div",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "number headline font-weight-bold",
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " " +
                                                                                                      _vm._s(
                                                                                                        _vm.totalTapInstalls
                                                                                                      ) +
                                                                                                      " "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          ),
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "v-row",
                                                                                [
                                                                                  _c(
                                                                                    "v-col",
                                                                                    {
                                                                                      attrs: {
                                                                                        cols:
                                                                                          "12",
                                                                                        lg:
                                                                                          "12",
                                                                                        md:
                                                                                          "12",
                                                                                        sm:
                                                                                          "12",
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "ChartForCompany",
                                                                                        {
                                                                                          attrs: {
                                                                                            chartdata:
                                                                                              _vm.appsChartData,
                                                                                            options:
                                                                                              _vm.chartOptions,
                                                                                          },
                                                                                          on: {
                                                                                            granularityChanged:
                                                                                              _vm.handleGranularityChange,
                                                                                            appChanged:
                                                                                              _vm.handleAppChange,
                                                                                          },
                                                                                        }
                                                                                      ),
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _vm.deleteTrialCheck &&
                                                                              _vm.invoiceCheck
                                                                                ? _c(
                                                                                    "v-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "ml-4",
                                                                                      attrs: {
                                                                                        color:
                                                                                          "primary",
                                                                                        depressed:
                                                                                          "",
                                                                                      },
                                                                                      on: {
                                                                                        click: function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm.deleteCompany()
                                                                                        },
                                                                                      },
                                                                                    },
                                                                                    [
                                                                                      _vm._v(
                                                                                        "Delete Company"
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ],
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-tab-item",
                                                                    {
                                                                      attrs: {
                                                                        id:
                                                                          "account-integrated",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "statistics-col pa-2 ma-4",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-row",
                                                                            _vm._l(
                                                                              _vm.integrations,
                                                                              function (
                                                                                item,
                                                                                i
                                                                              ) {
                                                                                return _c(
                                                                                  "v-col",
                                                                                  {
                                                                                    key: i,
                                                                                    attrs: {
                                                                                      cols:
                                                                                        "12",
                                                                                      lg:
                                                                                        "3",
                                                                                      md:
                                                                                        "3",
                                                                                      sm:
                                                                                        "6",
                                                                                    },
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-card",
                                                                                      {
                                                                                        staticClass:
                                                                                          "pa-6 stastists-box d-flex flex-row justify-space-between",
                                                                                        attrs: {
                                                                                          outlined:
                                                                                            "",
                                                                                          tile:
                                                                                            "",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "number d-flex flex-column align-center justify-center",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "p",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "ma-0",
                                                                                              },
                                                                                              [
                                                                                                _vm._v(
                                                                                                  _vm._s(
                                                                                                    item.type
                                                                                                  )
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  " " +
                                                                                                    _vm._s(
                                                                                                      item.name
                                                                                                    ) +
                                                                                                    " "
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          {
                                                                                            staticClass:
                                                                                              "ml-2 greyxdark--text",
                                                                                            on: {
                                                                                              click: function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.openDeleteIntegrationDialog(
                                                                                                  item.IntegrationID,
                                                                                                  item.type
                                                                                                )
                                                                                              },
                                                                                            },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "mdi-delete-outline"
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              }
                                                                            ),
                                                                            1
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _c(
                                                                    "v-tab-item",
                                                                    {
                                                                      attrs: {
                                                                        id:
                                                                          "apps",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-card",
                                                                        {
                                                                          staticClass:
                                                                            "pt-0",
                                                                          attrs: {
                                                                            flat:
                                                                              "",
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-data-table",
                                                                            {
                                                                              attrs: {
                                                                                loading:
                                                                                  _vm
                                                                                    .$apollo
                                                                                    .queries
                                                                                    .appsReportsForCompany
                                                                                    .loading,
                                                                                items:
                                                                                  (_vm.appsReportFinal &&
                                                                                    _vm
                                                                                      .appsReportFinal
                                                                                      .row) ||
                                                                                  [],
                                                                                headers:
                                                                                  _vm.appsHeaders,
                                                                                "items-per-page": 10,
                                                                                "no-data-text": _vm.$t(
                                                                                  "kai.showDataText"
                                                                                ),
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-tab-item",
                                                                    {
                                                                      attrs: {
                                                                        id:
                                                                          "campaigns",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-card",
                                                                        {
                                                                          staticClass:
                                                                            "pt-0",
                                                                          attrs: {
                                                                            flat:
                                                                              "",
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-data-table",
                                                                            {
                                                                              attrs: {
                                                                                items:
                                                                                  (_vm.liveCampaignReports &&
                                                                                    _vm
                                                                                      .liveCampaignReports
                                                                                      .row) ||
                                                                                  [],
                                                                                headers:
                                                                                  _vm.campaignHeaders,
                                                                                "items-per-page": 10,
                                                                                "no-data-text": _vm.$t(
                                                                                  "kai.showDataText"
                                                                                ),
                                                                              },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-tab-item",
                                                                    {
                                                                      attrs: {
                                                                        id:
                                                                          "staff",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-card",
                                                                        {
                                                                          staticClass:
                                                                            "pt-0",
                                                                          attrs: {
                                                                            flat:
                                                                              "",
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-data-table",
                                                                            {
                                                                              attrs: {
                                                                                headers:
                                                                                  _vm.staffheaders,
                                                                                items:
                                                                                  _vm.companyStaff,
                                                                                "items-per-page": 10,
                                                                                "no-data-text": _vm.$t(
                                                                                  "kai.showDataText"
                                                                                ),
                                                                              },
                                                                              scopedSlots: _vm._u(
                                                                                [
                                                                                  {
                                                                                    key:
                                                                                      "item.CreatedOn",
                                                                                    fn: function (
                                                                                      ref
                                                                                    ) {
                                                                                      var item =
                                                                                        ref.item
                                                                                      return [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              item.CreatedOn.substr(
                                                                                                0,
                                                                                                10
                                                                                              )
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                  {
                                                                                    key:
                                                                                      "item.actions",
                                                                                    fn: function (
                                                                                      ref
                                                                                    ) {
                                                                                      var item =
                                                                                        ref.item
                                                                                      return [
                                                                                        _c(
                                                                                          "v-tooltip",
                                                                                          {
                                                                                            attrs: {
                                                                                              bottom:
                                                                                                "",
                                                                                              color:
                                                                                                "primarydark",
                                                                                            },
                                                                                            scopedSlots: _vm._u(
                                                                                              [
                                                                                                {
                                                                                                  key:
                                                                                                    "activator",
                                                                                                  fn: function (
                                                                                                    ref
                                                                                                  ) {
                                                                                                    var on =
                                                                                                      ref.on
                                                                                                    var attrs =
                                                                                                      ref.attrs
                                                                                                    return [
                                                                                                      _c(
                                                                                                        "v-icon",
                                                                                                        _vm._g(
                                                                                                          _vm._b(
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "mr-2 font-22 icon-hover",
                                                                                                              attrs: {
                                                                                                                small:
                                                                                                                  "",
                                                                                                                color:
                                                                                                                  "primary",
                                                                                                              },
                                                                                                              on: {
                                                                                                                click: function (
                                                                                                                  $event
                                                                                                                ) {
                                                                                                                  return _vm.archiveStaffMember(
                                                                                                                    item
                                                                                                                  )
                                                                                                                },
                                                                                                              },
                                                                                                            },
                                                                                                            "v-icon",
                                                                                                            attrs,
                                                                                                            false
                                                                                                          ),
                                                                                                          on
                                                                                                        ),
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " mdi-delete-outline "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  },
                                                                                                },
                                                                                              ],
                                                                                              null,
                                                                                              true
                                                                                            ),
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Delete"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "v-tooltip",
                                                                                          {
                                                                                            attrs: {
                                                                                              bottom:
                                                                                                "",
                                                                                              color:
                                                                                                "primarydark",
                                                                                            },
                                                                                            scopedSlots: _vm._u(
                                                                                              [
                                                                                                {
                                                                                                  key:
                                                                                                    "activator",
                                                                                                  fn: function (
                                                                                                    ref
                                                                                                  ) {
                                                                                                    var on =
                                                                                                      ref.on
                                                                                                    var attrs =
                                                                                                      ref.attrs
                                                                                                    return [
                                                                                                      _c(
                                                                                                        "v-icon",
                                                                                                        _vm._g(
                                                                                                          _vm._b(
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "mr-2 font-22 icon-hover",
                                                                                                              attrs: {
                                                                                                                small:
                                                                                                                  "",
                                                                                                                color:
                                                                                                                  "primary",
                                                                                                              },
                                                                                                              on: {
                                                                                                                click: function (
                                                                                                                  $event
                                                                                                                ) {
                                                                                                                  return _vm.generateResetPasswordLink(
                                                                                                                    item
                                                                                                                  )
                                                                                                                },
                                                                                                              },
                                                                                                            },
                                                                                                            "v-icon",
                                                                                                            attrs,
                                                                                                            false
                                                                                                          ),
                                                                                                          on
                                                                                                        ),
                                                                                                        [
                                                                                                          _vm._v(
                                                                                                            " mdi-key-link "
                                                                                                          ),
                                                                                                        ]
                                                                                                      ),
                                                                                                    ]
                                                                                                  },
                                                                                                },
                                                                                              ],
                                                                                              null,
                                                                                              true
                                                                                            ),
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "span",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "Generate recovery link"
                                                                                                ),
                                                                                              ]
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                  },
                                                                                ],
                                                                                null,
                                                                                true
                                                                              ),
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "v-tab-item",
                                                                    {
                                                                      attrs: {
                                                                        id:
                                                                          "usage",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-card",
                                                                        {
                                                                          attrs: {
                                                                            flat:
                                                                              "",
                                                                          },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-card-text",
                                                                            {
                                                                              staticClass:
                                                                                "pa-0",
                                                                            },
                                                                            [
                                                                              [
                                                                                _c(
                                                                                  "v-data-table",
                                                                                  {
                                                                                    staticClass:
                                                                                      "elevation-0",
                                                                                    attrs: {
                                                                                      headers:
                                                                                        _vm.loginActivityHeaders,
                                                                                      items:
                                                                                        _vm.loginActivity,
                                                                                      loading:
                                                                                        _vm.loading,
                                                                                    },
                                                                                    scopedSlots: _vm._u(
                                                                                      [
                                                                                        {
                                                                                          key:
                                                                                            "item._id",
                                                                                          fn: function (
                                                                                            ref
                                                                                          ) {
                                                                                            var item =
                                                                                              ref.item
                                                                                            return [
                                                                                              _vm._v(
                                                                                                " " +
                                                                                                  _vm._s(
                                                                                                    _vm
                                                                                                      .moment(
                                                                                                        item._id
                                                                                                      )
                                                                                                      .format(
                                                                                                        "DD-MM-YYYY"
                                                                                                      )
                                                                                                  ) +
                                                                                                  " "
                                                                                              ),
                                                                                            ]
                                                                                          },
                                                                                        },
                                                                                        {
                                                                                          key:
                                                                                            "item.LoginActivity",
                                                                                          fn: function (
                                                                                            ref
                                                                                          ) {
                                                                                            var item =
                                                                                              ref.item
                                                                                            return [
                                                                                              new Set(
                                                                                                item.LoginActivity.map(
                                                                                                  function (
                                                                                                    item
                                                                                                  ) {
                                                                                                    return item
                                                                                                      .user
                                                                                                      .Email
                                                                                                  }
                                                                                                )
                                                                                              )
                                                                                                .size >
                                                                                              1
                                                                                                ? _c(
                                                                                                    "p",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            item
                                                                                                              .LoginActivity[0]
                                                                                                              .user
                                                                                                              .FirstName
                                                                                                          ) +
                                                                                                          " " +
                                                                                                          _vm._s(
                                                                                                            item
                                                                                                              .LoginActivity[0]
                                                                                                              .user
                                                                                                              .LastName
                                                                                                          ) +
                                                                                                          " and " +
                                                                                                          _vm._s(
                                                                                                            new Set(
                                                                                                              item.LoginActivity.map(
                                                                                                                function (
                                                                                                                  item
                                                                                                                ) {
                                                                                                                  return item
                                                                                                                    .user
                                                                                                                    .Email
                                                                                                                }
                                                                                                              )
                                                                                                            )
                                                                                                              .size -
                                                                                                              1
                                                                                                          ) +
                                                                                                          " others "
                                                                                                      ),
                                                                                                    ]
                                                                                                  )
                                                                                                : _c(
                                                                                                    "p",
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        " " +
                                                                                                          _vm._s(
                                                                                                            item
                                                                                                              .LoginActivity[0]
                                                                                                              .user
                                                                                                              .FirstName
                                                                                                          ) +
                                                                                                          " " +
                                                                                                          _vm._s(
                                                                                                            item
                                                                                                              .LoginActivity[0]
                                                                                                              .user
                                                                                                              .LastName
                                                                                                          ) +
                                                                                                          " "
                                                                                                      ),
                                                                                                    ]
                                                                                                  ),
                                                                                            ]
                                                                                          },
                                                                                        },
                                                                                        {
                                                                                          key:
                                                                                            "item.LoginActivityCount",
                                                                                          fn: function (
                                                                                            ref
                                                                                          ) {
                                                                                            var item =
                                                                                              ref.item
                                                                                            return [
                                                                                              _vm._v(
                                                                                                " " +
                                                                                                  _vm._s(
                                                                                                    new Set(
                                                                                                      item.LoginActivity.map(
                                                                                                        function (
                                                                                                          item
                                                                                                        ) {
                                                                                                          return item
                                                                                                            .user
                                                                                                            .Email
                                                                                                        }
                                                                                                      )
                                                                                                    )
                                                                                                      .size
                                                                                                  ) +
                                                                                                  " "
                                                                                              ),
                                                                                            ]
                                                                                          },
                                                                                        },
                                                                                        {
                                                                                          key:
                                                                                            "item.actions",
                                                                                          fn: function (
                                                                                            ref
                                                                                          ) {
                                                                                            var item =
                                                                                              ref.item
                                                                                            return [
                                                                                              _c(
                                                                                                "v-icon",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "mr-1 icon-hover",
                                                                                                  attrs: {
                                                                                                    medium:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "primary",
                                                                                                    dark:
                                                                                                      "",
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function (
                                                                                                      $event
                                                                                                    ) {
                                                                                                      return _vm.usageDialogFn(
                                                                                                        item.LoginActivity
                                                                                                      )
                                                                                                    },
                                                                                                  },
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    " mdi-eye-outline "
                                                                                                  ),
                                                                                                ]
                                                                                              ),
                                                                                            ]
                                                                                          },
                                                                                        },
                                                                                      ]
                                                                                    ),
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  {},
                                                                                  [
                                                                                    _c(
                                                                                      "v-dialog",
                                                                                      {
                                                                                        attrs: {
                                                                                          "max-width":
                                                                                            "600px",
                                                                                          persistent:
                                                                                            "",
                                                                                        },
                                                                                        model: {
                                                                                          value:
                                                                                            _vm.usageDialog,
                                                                                          callback: function (
                                                                                            $$v
                                                                                          ) {
                                                                                            _vm.usageDialog = $$v
                                                                                          },
                                                                                          expression:
                                                                                            "usageDialog",
                                                                                        },
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "v-card",
                                                                                          {
                                                                                            staticClass:
                                                                                              "v-modal-fixed style-2",
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-card-title",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "headline d-flex justify-space-between px-5 py-3 v-modal-header",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "h5",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "text-h6",
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "View Activity"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                                _c(
                                                                                                  "v-icon",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "black--text",
                                                                                                    on: {
                                                                                                      click: function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        _vm.usageDialog = false
                                                                                                      },
                                                                                                    },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      "mdi-close"
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                            _c(
                                                                                              "v-card-text",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "pt-4 pb-4",
                                                                                              },
                                                                                              [
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-container",
                                                                                                    {
                                                                                                      staticClass:
                                                                                                        "pa-0",
                                                                                                    },
                                                                                                    [
                                                                                                      _c(
                                                                                                        "v-data-table",
                                                                                                        {
                                                                                                          staticClass:
                                                                                                            "elevation-0",
                                                                                                          attrs: {
                                                                                                            headers:
                                                                                                              _vm.loginActivityDetailHeaders,
                                                                                                            items:
                                                                                                              _vm.loginActivityDetail,
                                                                                                            loading:
                                                                                                              _vm.loading,
                                                                                                          },
                                                                                                          scopedSlots: _vm._u(
                                                                                                            [
                                                                                                              {
                                                                                                                key:
                                                                                                                  "item.TimeStamp",
                                                                                                                fn: function (
                                                                                                                  ref
                                                                                                                ) {
                                                                                                                  var item =
                                                                                                                    ref.item
                                                                                                                  return [
                                                                                                                    _vm._v(
                                                                                                                      " " +
                                                                                                                        _vm._s(
                                                                                                                          _vm
                                                                                                                            .moment(
                                                                                                                              item.TimeStamp
                                                                                                                            )
                                                                                                                            .format(
                                                                                                                              "h:mm a"
                                                                                                                            )
                                                                                                                        ) +
                                                                                                                        " "
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                },
                                                                                                              },
                                                                                                              {
                                                                                                                key:
                                                                                                                  "item.user",
                                                                                                                fn: function (
                                                                                                                  ref
                                                                                                                ) {
                                                                                                                  var item =
                                                                                                                    ref.item
                                                                                                                  return [
                                                                                                                    _vm._v(
                                                                                                                      " " +
                                                                                                                        _vm._s(
                                                                                                                          item
                                                                                                                            .user
                                                                                                                            .FirstName
                                                                                                                        ) +
                                                                                                                        " " +
                                                                                                                        _vm._s(
                                                                                                                          item
                                                                                                                            .user
                                                                                                                            .LastName
                                                                                                                        ) +
                                                                                                                        " "
                                                                                                                    ),
                                                                                                                  ]
                                                                                                                },
                                                                                                              },
                                                                                                            ]
                                                                                                          ),
                                                                                                        }
                                                                                                      ),
                                                                                                    ],
                                                                                                    1
                                                                                                  ),
                                                                                                ],
                                                                                              ],
                                                                                              2
                                                                                            ),
                                                                                            _c(
                                                                                              "v-card-actions",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "justify-end mt-0 pa-5",
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "v-btn",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      width:
                                                                                                        "127",
                                                                                                      color:
                                                                                                        "grey",
                                                                                                      depressed:
                                                                                                        "",
                                                                                                      outlined:
                                                                                                        "",
                                                                                                    },
                                                                                                    on: {
                                                                                                      click: function (
                                                                                                        $event
                                                                                                      ) {
                                                                                                        _vm.usageDialog = false
                                                                                                      },
                                                                                                    },
                                                                                                  },
                                                                                                  [
                                                                                                    _vm._v(
                                                                                                      " Close "
                                                                                                    ),
                                                                                                  ]
                                                                                                ),
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                          ],
                                                                                          1
                                                                                        ),
                                                                                      ],
                                                                                      1
                                                                                    ),
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                              ],
                                                                            ],
                                                                            2
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.showCL
                          ? _c(
                              "div",
                              { staticClass: "col-right-main user-slider" },
                              [
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "mx-auto",
                                    attrs: {
                                      "max-width": "466",
                                      elevation: "1",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-card-title",
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.$t("checklist.title")) +
                                            " "
                                        ),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: { elevation: "0", icon: "" },
                                            on: {
                                              click: function ($event) {
                                                _vm.showCL = false
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "svg",
                                              {
                                                attrs: {
                                                  width: "16",
                                                  height: "16",
                                                  viewBox: "0 0 16 16",
                                                  fill: "none",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg",
                                                },
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d:
                                                      "M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z",
                                                    fill: "black",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-card-text",
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "titleText" },
                                          [
                                            _c("v-icon", {
                                              staticClass: "mr-2",
                                              attrs: { color: _vm.color0 },
                                              domProps: {
                                                textContent: _vm._s(_vm.check0),
                                              },
                                            }),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/integrations",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "checklist.integrations"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass: "cust-list",
                                            attrs: { lang: _vm.locale },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    size: "15",
                                                    color: _vm.color1,
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check1
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/integrations",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.searchads"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass: "cust-list",
                                            attrs: { lang: _vm.locale },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    size: "15",
                                                    color: _vm.color6,
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check6
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/integrations",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.slack"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass: "cust-list",
                                            attrs: { lang: _vm.locale },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    size: "15",
                                                    color: _vm.mmpIntColor,
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.mmpIntCheck
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/integrations",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.mmpIntegration"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-2",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "titleText" },
                                          [
                                            _c("v-icon", {
                                              staticClass: "mr-2",
                                              attrs: { color: _vm.color11 },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.check11
                                                ),
                                              },
                                            }),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href:
                                                    "/keyword-auction-insights",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("checklist.kai")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass: "cust-list",
                                            attrs: { lang: _vm.locale },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color2,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check2
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        "/keyword-auction-insights?tab=comp",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.competitorMsg"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color8,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check8
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        "/keyword-auction-insights?tab=app",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.searchKeywords"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color9,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check9
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        "/keyword-auction-insights?tab=key",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.seeApps"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color10,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check10
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        "/keyword-auction-insights?tab=top",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.topCharts"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-2",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "titleText" },
                                          [
                                            _c("v-icon", {
                                              staticClass: "mr-2",
                                              attrs: { color: _vm.color16 },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.check16
                                                ),
                                              },
                                            }),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/create-campaign",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("checklist.campaign")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-list-item",
                                          {
                                            staticClass: "cust-list",
                                            attrs: { lang: _vm.locale },
                                          },
                                          [
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color12,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check12
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/create-campaign",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.initCampaign"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color13,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check13
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/create-campaign",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.createAdgroup"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color14,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check14
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/create-campaign",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.addKeywords"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color15,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check15
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/create-campaign",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.addCreativeSet"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-list-item-content",
                                              [
                                                _c("v-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    color: _vm.color3,
                                                    size: "15",
                                                  },
                                                  domProps: {
                                                    textContent: _vm._s(
                                                      _vm.check3
                                                    ),
                                                  },
                                                }),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href: "/ads-manager",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$t(
                                                          "checklist.goLive"
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-2",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "titleText" },
                                          [
                                            _c("v-icon", {
                                              staticClass: "mr-2",
                                              attrs: { color: _vm.color5 },
                                              domProps: {
                                                textContent: _vm._s(_vm.check5),
                                              },
                                            }),
                                            _c(
                                              "a",
                                              { attrs: { href: "/overview" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "checklist.reviewMetrics"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-2",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "titleText" },
                                          [
                                            _c("v-icon", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                color: _vm.campGenColor,
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.campGenCheck
                                                ),
                                              },
                                            }),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/campaign-generator",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("checklist.campGen")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-2",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "titleText" },
                                          [
                                            _c("v-icon", {
                                              staticClass: "mr-2",
                                              attrs: { color: _vm.color4 },
                                              domProps: {
                                                textContent: _vm._s(_vm.check4),
                                              },
                                            }),
                                            _c(
                                              "a",
                                              { attrs: { href: "/arrules" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("checklist.automate")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-2",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "titleText" },
                                          [
                                            _c("v-icon", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                color:
                                                  _vm.scheduledReportsColor,
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.scheduledReportsCheck
                                                ),
                                              },
                                            }),
                                            _c(
                                              "a",
                                              {
                                                attrs: {
                                                  href: "/schedule-reports",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "checklist.scheduledReports"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("v-divider", {
                                          staticClass: "my-2",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "titleText pb-4" },
                                          [
                                            _c("v-icon", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                color: _vm.createMmpGoalsColor,
                                              },
                                              domProps: {
                                                textContent: _vm._s(
                                                  _vm.createMmpGoalsCheck
                                                ),
                                              },
                                            }),
                                            _c(
                                              "a",
                                              { attrs: { href: "/goals" } },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "checklist.createMmpGoals"
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
              ],
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c(
                "v-dialog",
                {
                  attrs: { "max-width": "600", persistent: "" },
                  model: {
                    value: _vm.resetLinkDialog,
                    callback: function ($$v) {
                      _vm.resetLinkDialog = $$v
                    },
                    expression: "resetLinkDialog",
                  },
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "headline grey lighten-2" },
                        [_vm._v(" Recovery Link ")]
                      ),
                      _c("v-card-text", [
                        _c(
                          "div",
                          [
                            _c(
                              "v-row",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "12" } },
                                  [
                                    _c("v-textarea", {
                                      staticClass: "mx-2",
                                      attrs: {
                                        "append-outer-icon": "mdi-content-copy",
                                        rows: "3",
                                        readonly: "",
                                        disabled: !_vm.resetLink,
                                      },
                                      on: {
                                        "click:append-outer": function (
                                          $event
                                        ) {
                                          return _vm.copyRecoveryLink()
                                        },
                                      },
                                      model: {
                                        value: _vm.resetLink,
                                        callback: function ($$v) {
                                          _vm.resetLink = $$v
                                        },
                                        expression: "resetLink",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              { staticClass: "mt-2" },
                              [
                                _c(
                                  "v-col",
                                  { attrs: { cols: "12", sm: "12" } },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "mb-4 mt-0 secondary--text font-18 medium-font",
                                        staticStyle: {
                                          "margin-left": "0.4rem",
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.resetLinkMsg))]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c("v-divider"),
                      _c(
                        "v-card-actions",
                        [
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "primary", text: "" },
                              on: {
                                click: function ($event) {
                                  _vm.resetLinkDialog = false
                                },
                              },
                            },
                            [_vm._v(" " + _vm._s(_vm.$t("infoAlert.ok")) + " ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("AICredits", {
            attrs: {
              creditsDetails: _vm.userCreditsData,
              companyWithManualLimit: _vm.memberDetails.filter(function (e) {
                return e.aiCredits.isFilledManually
              }),
            },
            on: {
              done: function ($event) {
                return _vm.aiCreditsActionDone()
              },
            },
          }),
          _c("DeleteCompany", {
            attrs: { companyDeletionMetadata: _vm.companyDeletionMetadata },
            on: {
              onDeleteCompany: function ($event) {
                return _vm.onUpdate()
              },
            },
          }),
          _c("DeleteTestUsers", {
            attrs: { testUsersMetadata: _vm.testUsersMetadata },
            on: {
              onDeleteCompany: function ($event) {
                return _vm.onUpdate()
              },
            },
          }),
          _c("ArchiveStaffMember", {
            attrs: { archiveStaffMemberData: _vm.archiveStaffMemberData },
            on: {
              onArchiveStaff: function ($event) {
                return _vm.onArchiveStaff()
              },
            },
          }),
          _c("DecisionAlert", {
            attrs: {
              DecisionAlert: _vm.decisionAlertFlag,
              DecisionAlertTitle: _vm.decisionAlertTitle,
              DecisionAlertIcon: _vm.decisionAlertIcon,
              DecisionAlertMessage: _vm.decisionAlertMessage,
              DecisionAlertData: _vm.decisionAlertData,
            },
            on: {
              emitDecisionAlertCancelClicked: _vm.clearDecisionModel,
              emitDecisionAlertOkClicked: function ($event) {
                return _vm.decisionOkAction($event)
              },
            },
          }),
          _c("DeleteIntegration", {
            attrs: { integrationDetailsForDeletion: _vm.integrationData },
          }),
          _c("RemoveUser", {
            attrs: { removeUserMetadata: _vm.removeUserMetadata },
            on: {
              onRemoveUser: function ($event) {
                return _vm.onRemoveUser()
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }